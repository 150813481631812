export default () => {
  if (!document.getElementsByClassName('login-by-phone').length) {
    const main = document.createElement('div');
    const title = document.createElement('p');
    const text = document.createElement('p');
    const refreshLink = document.createElement('a');

    main.className = 'error ad-blocker-error';
    title.className = 'error-text ad-blocker-error-text';
    text.className = 'error-text';
    refreshLink.className = 'error-link';
    refreshLink.onclick = () => window.location.reload();

    title.appendChild(document.createTextNode('Сайт может работать не корректно!'));
    text.appendChild(document.createTextNode(' Чтобы войти по номеру телефона, необходимо отключить блокировщики рекламы.'));
    refreshLink.appendChild(document.createTextNode('Обновить страницу'));

    main.appendChild(title);
    main.appendChild(text);
    main.appendChild(refreshLink);

    document.body.appendChild(main);
  }
};
